import request from "@/utils/request"
import requestProgress from "@/utils/requestProgress"
import commonApi from "@/api/api"

const moduleName = "/project"

export default {
	...commonApi(moduleName),
	// 获取项目下的文件列表
	apiGetPage: data =>
		request(`/project/${data.projectId}/docfiles`, {
			method: "get",
			params: data
		}),
	// 新增文件夹
	addFile: data =>
		request(`/project/${data.projectId}/docfiles/mkdir`, {
			method: "post",
			body: data
		}),
	// 上传临时文件
	uploadDraftFile: (data, onprogress) =>
		requestProgress(
			`/project/${data.projectId}/docfiles/mkfile`,
			{
				method: "post",
				body: data.body
			},
			onprogress
		),
	// 提交上传的文件
	uploadFile: data =>
		request(`/project/${data.projectId}/docfile/${data.pid}/confirm`, {
			method: "post",
			body: data
		}),
	// 删除流程文档库单个暂存文件
	deleteProFile: data =>
		request(`/project/${data.projectId}/docfile/del`, {
			method: "delete",
			params: data
		}),
	// 删除流程文档库草稿箱暫存文件
	deleteDraftFile: data =>
		request(`/project/${data.projectId}/docfile/${data.pid}/cancel`, {
			method: "delete"
		}),
	// 删除文件
	deleteFile: data =>
		request(`/project/${data.projectId}/docfile/${data.docfileId}`, {
			method: "delete"
		}),
	// 替换文件
	replaceFile: data =>
		request(`/project/${data.projectId}/docfile/${data.docfileId}`, {
			method: "post",
			body: data.body
		}),
	// 获取文件的操作记录暂不使用
	getLogs: data =>
		request(`/project/${data.projectId}/docfile/${data.id}/logs`, {
			method: "get"
		}),
	// 文件切换暂不使用
	toggleFile: data =>
		request(`/project/${data.projectId}/docfile/${data.docfileId}`, {
			method: "put",
			body: data
		}),
	// 获取权限列表
	apiGetPermission: data =>
		request(`/project/${data.projectId}/docfile/${data.docfileId}/grant-role`, {
			method: "get",
			params: { pid: data.pid }
		}),
	// 权限分配
	apiSetPermission: data =>
		request(`/project/${data.projectId}/docfile/${data.docfileId}/grant-role`, {
			method: "put",
			body: data
		}),
	// 获取表单的html进行预览
	apiGetFormHtml: data =>
		request(`/flowable/form-data/${data.id}`, { method: "get" }),
	// 打包文件
	packageFile: data =>
		request(`/project/${data.projectId}/docfile/${data.docfileId}/zip`, {
			method: "post"
		}),
	// 获取文件目录树
	getTreeList: data =>
		request(`/project/${data.projectId}/docfiles/tree`, { method: "get" }),
	// 移动文件
	moveFile: data =>
		request(`/project/${data.projectId}/docfiles/move`, {
			method: "post",
			body: data
		}),

	// 文件版本号更新
	versionNumberUpdate: data =>
		request(
			`/project/${data.projectId}/docfile/${data.docfileId}/version-number-update`,
			{
				method: "put",
				body: data
			}
		),

	// 文件版本更新
	versionUpdate: (data, fd) =>
		request(
			`/project/${data.projectId}/docfile/${data.docfileId}/version-update`,
			{
				method: "post",
				body: fd
			}
		),

	// 文件 及 版本号 更新
	versionFileUpdate: (data, fd) =>
		request(
			`/project/${data.projectId}/docfile/${data.docfileId}/file-update`,
			{
				method: "post",
				params: {
					md5: data.md5,
					version: data.version
				},
				body: fd
			}
		),
	// 审核文件
	auditFile: data =>
		request(`/project/${data.projectId}/docfile/${data.docfileId}/audit`, {
			method: "post",
			body: data
		}),
	// 排序
	sortFile: data =>
		request(`/project/${data.projectId}/docfiles/sort-file`, {
			method: "post",
			body: data
		}),
	// 打包下载流程文档库
	downloadZip: data =>
		request(`/project/${data.projectId}/flow/zip`, {
			method: "post",
			body: data
		})
}
