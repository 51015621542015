<template>
	<Modal
		title="版本记录"
		class="version-history-list-wrapper"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<CtmsDataGrid
			:height="500"
			:columns="columns"
			:data="listData"
			:page="false"
		>
			<template slot-scope="{ index, row }" slot="name">
				<span @click="selectFile(row)" class="version-file-name">
					{{ row.name }}
				</span>
			</template>
		</CtmsDataGrid>
		<div class="drawer-footer">
			<Button style="margin-right: 8px" @click="onCancel">关闭</Button>
		</div>
		<DocumentPreview
			:visible="documentPreviewVisible"
			:isFullScreen="true"
			:documentSrc="documentSrc"
			@onCancel="documentPreviewVisible = false"
			:id="`processVersionDocumentPreview${sourceId}`"
		></DocumentPreview>
	</Modal>
</template>

<script>
import api from "@/api/document/docfile"
import docType from "@/config/docType"
import DocumentPreview from "./DocumentPreview.vue"

const { getVersionList, getFileExist } = api

export default {
	name: "VersionHistoryList",
	props: {
		visible: Boolean,
		sourceId: String,
		projectId: String,
		entry: String
	},
	components: {
		DocumentPreview
	},
	data() {
		return {
			columns: [
				{
					title: "文件名称",
					key: "name",
					minWidth: 160,
					slot: "name"
				},
				{
					title: "文件版本",
					key: "version",
					width: 90
				},
				{
					title: "更新人",
					key: "createdBy",
					minWidth: 160
				},
				{
					title: "更新时间",
					key: "versionTime",
					width: 180
				}
			],
			listData: [],
			loading: false,
			docType,
			documentPreviewVisible: false,
			documentSrc: "",
			proId: ""
		}
	},
	watch: {
		visible() {
			if (this.visible && this.sourceId) {
				if (this.$route.params.projectId) {
					this.proId = this.$route.params.projectId
				} else {
					this.proId = this.projectId
				}
				this.getVersionList()
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("update:visible", false)
		},
		async getVersionList() {
			this.loading = true
			const res = await getVersionList({
				sourceId: this.sourceId,
				projectId: this.proId
			})
			if (res) {
				this.listData = res.data
			}
			this.loading = false
		},
		selectFile(row) {
			this.$asyncDo(async () => {
				const res = await getFileExist(row.id)
				if (res) {
					// 先判断文件存在，再进行预览
					const suffix = row.suffix ? row.suffix.toUpperCase() : ""
					if (this.docType.previewTypes.indexOf(suffix) > -1) {
						this.documentSrc = `${this.$baseUrl}/project/${this.proId}/docfile/${row.id}/inline?token=${this.$store.state.user.token}`
						this.documentPreviewVisible = true
					} else if (
						this.entry === "1" &&
						(!row.allowDownload || !row.suffix)
					) {
						this.$Message.error("当前用户没有下载权限")
					} else {
						window.open(
							`${this.$baseUrl}/project/${this.proId}/docfile/${row.id}/download?token=${this.$store.state.user.token}`
						)
					}
				}
			})
		}
	}
}
</script>

<style lang="less">
@import "../../../my-theme/color.less";
.version-history-list-wrapper {
	.version-file-name {
		cursor: pointer;
		&:hover {
			color: @primary-color;
		}
	}
}
</style>
