import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/document/docfiles"

export default {
	...commonApi(moduleName),
	// 新增文件夹
	addFile: data =>
		request(`${moduleName}/mkdir`, { method: "post", body: data }),
	// 上传文件
	uploadFile: data =>
		request(`${moduleName}/mkfile`, { method: "post", body: data.body }),
	deleteFile: data =>
		request(`/document/docfile/${data.docfileId}`, {
			method: "delete",
			body: data
		}),
	apiGetPermission: data =>
		request(`/document/docfile/${data.docfileId}/grant-role`, {
			method: "get",
			params: { grantType: data.grantType, pid: data.pid }
		}),
	apiSetPermission: data =>
		request(`/document/docfile/${data.docfileId}/grant-role`, {
			method: "put",
			body: data
		}),
	getVersionList: data =>
		request(`/project/${data.projectId}/docfiles/versions-query`, {
			params: data
		}),
	// 判断文件是否存在
	getFileExist: docfileId => request(`/common/file/docfile/${docfileId}`),
	// 排序
	sortFile: data =>
		request("/document/docfiles/sort-file", { method: "post", body: data })
}
